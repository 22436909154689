<!--行业智库详情-->
<template>
  <div class="thinkTanksDiv">
    <el-row class="outerRow">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <el-row class="outerRow">
      <div class="peopleDiv">
        <div class="peopleList cursorPointer" v-for="(item, index) in peopleList" :key="index"
             @click="toDetails(item)" :style="{ backgroundColor: colorList[index % 8] }">
          <div class="peopleImg">
            <img :src="item.url" alt="">
<!--            <img src="@/assets/img/giia/avator.png">-->
          </div>
          <div class="peopleContent">
            <div class="title">{{item.tankName}}</div>
            <div class="subTitle">({{item.tankPost}}):</div>
            <div class="content">
              <text-ellipsis :text="item.tankIntroduce" :height="230" style="width: 100%">
                <template slot="more">...</template>
              </text-ellipsis>
            </div>
          </div>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from 'axios'
import textEllipsis from '@/components/textellipsis'
export default {
  name: "thinkTanksDetail",
  components: { topPathList, textEllipsis },
  data() {
    return {
      defaultAvator: require("@/assets/img/giia/avator.png"),
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '行业智库',
          path: '/thinkTanks/thinkTanksDetail'
        },
      ],
      title: '行业智库',
      peopleList: [],
      colorList: ['#67a8cd', '#66cba7', '#67a8cd', '#66cba7',
        '#c8ac6a', '#c56873', '#c8ac6a', '#c56873'],
    }
  },
  methods: {
    //点击跳转详情
    toDetails(item) {
      this.$router.push({
        path: '/thinkTanks/thinkTanksDetails',
        query: {
          tankId: item.tankId
        }
      })
    },

    //接口调用
    getWisdomData() {
      let _this = this
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/think/queryPeopleList?isShow=1",
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          _this.peopleList = res.data.data
          let length = _this.peopleList.length
          for (let i = 0; i < length; i++) {
            if (_this.peopleList[i].tankPicUrl){
              _this.peopleList[i].url = this.peopleList[i].tankPicUrl
            }else{
              _this.peopleList[i].url = _this.defaultAvator
            }
          }
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },
  },
  mounted() {
    this.getWisdomData()
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/common.less';
  .thinkTanksDiv {
    text-align: center;
    background-color: #f8f9fa !important;
  }
  .breadTop {
    margin-left: 220px;
  }
  .news_title {
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 48px;
  }
  hr {
    margin-top: 30px;
    width: 62%;
  }
  .peopleDiv {
    min-height: 469px;
    margin: auto;
    display: grid;
    grid-template-columns: auto auto auto auto;
    width: 1200px;
    justify-content: space-around;
    background: #fff;
    padding: 15px 0;

    .peopleList {
      margin: 50px auto;
      border-radius: 5px;

      .peopleImg {
        margin-left: 85px;
        margin-top: -50px;
        position:absolute;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .peopleContent {
        width: 271px;
        height: 324px;
        background: transparent;
        color: #ffffff;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        letter-spacing: 2px;

        .title {
          background: transparent;
          margin-top: 60px;
          font-size: 20px;
          font-weight: bold;
        }
        .subTitle {
          background: transparent;
          margin: 20px 20px 0px 20px;
          text-align: left;
          font-size: 16px;
        }
        .content {
          background: transparent;
          margin: 0px 20px 20px 20px;
          text-align: justify;
          font-size: 16px;
        }
      }
    }
  }
</style>
